import { Popover, OverlayTrigger } from 'react-bootstrap';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useEffect } from 'react';
import { useState } from 'react';

const HelpIconPopOver = ({ text }) => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const popover = (
		<Popover id='popover-positioned-right'>
			<Popover.Body>{text}</Popover.Body>
		</Popover>
	);

	return (
		<OverlayTrigger
			trigger={['hover', 'click']}
			placement={isMobile ? 'top' : 'right'}
			overlay={popover}>
			<HelpOutlineIcon
				sx={{ fontSize: '18px', marginLeft: '8px', cursor: 'pointer', marginBottom: '2px' }}
			/>
		</OverlayTrigger>
	);
};

export default HelpIconPopOver;
