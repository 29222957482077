import React, { useContext, useEffect, useState } from 'react';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import '../../../pages/common/paymentPage/CheckoutPage.css';
import { Divider as DividerANT } from 'antd';
import { CheckBoxOutlineBlank } from '../../../components/icon/material-icons';
import { Checkbox, Divider, Radio } from '@mui/material';
import Input from '../../../components/bootstrap/forms/Input';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { handleCheckInputKeyUpReg, priceRegex } from '../vhelper/val';
import { useLocation, useNavigate } from 'react-router-dom';
import { userAuth } from '../../../contexts/userContext';
import apiService from '../../../api/apiService';
import { Popover } from 'react-tiny-popover';
import CopyToClipboard from 'react-copy-to-clipboard';
import Icon from '../../../components/icon/Icon';
import HelpIconPopOver from '../../presentation/demo-pages/helper/HelpIconPopOver';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';

const CheckoutPage = () => {
	const { token, setToken } = useContext(userAuth);
	const navigate = useNavigate();
	const [paymentMethod, setPaymentMethod] = useState(1);
	const [ServiceAmount, setSetServiceAmount] = useState('');
	const [totalAmount, setSetTotalAmount] = useState('');
	const [discountAmount, setDiscountAmount] = useState('');
	const [selectedValue, setSelectedValue] = useState('');
	const [isHalfPayment, setHalfPayment] = useState(false);
	const [advanceAmount, setAdvanceAmount] = useState('');
	const [isAmountError, setAmountError] = useState({ error: false, msg: '*Required' });
	const location = useLocation();
	const { bookings, discount, promocode, subtotal, netamt, currency, promo } =
		location.state || {};
	const [isPopoverOpen, setIsPopoverOpen] = useState([]);
	const [copied, setCopied] = useState([]);
	const [orderSummeryList, setOrderSummeryList] = useState([
		{
			id: 1025,
			bdate_formated: 'Oct 21',
			bfrom_formated: '01:00 AM',
			service_name: 'Hair Cut',
			service_duration: '15',
			service_price: '200',
			discount_amount: '100',
		},
		{
			id: 1026,
			bdate_formated: 'Oct 21',
			bfrom_formated: '01:00 AM',
			service_name: 'Hair Cut',
			service_duration: '15',
			service_price: '200',
			discount_amount: '0',
		},
	]);
	const [paymentMethodList, setPaymentMethodList] = useState([
		{
			id: 1,
			name: 'PayPal',
			icon: 'https://easyaffiliate.com/wp-content/uploads/2017/10/PayPal@2x.png',
		},
		{
			id: 2,
			name: 'Venmo',
			icon: 'https://www.qgiv.com/blog/wp-content/uploads/2023/05/venmo-logo-1024x269.png',
		},
		{
			id: 3,
			name: 'Google Pay',
			icon: 'https://lh3.googleusercontent.com/S63eLxWYDglcFV4eDIOE8uyTN4Eseo3hCOM3zhoF8YWKVSMNhHM_Pg9iYzN1bTwUwirTRnFRPj7BSkTH0QlGmQ2D00TrsUgbMb2tsA',
		},
		{
			id: 4,
			name: 'Bank Account',
			icon: 'https://www.shutterstock.com/image-vector/bank-building-architecture-facade-government-600nw-2440534455.jpg',
		},
	]);

	useEffect(() => {
		if (!bookings || bookings.length === 0) {
			navigate('/cart', { replace: true });
		} else {
			setCopied([]);
			const serviceAmount = subtotal?.toFixed(2);
			const discountAmt = discount && discount?.toFixed(2);
			const totalAmt = netamt > 0 ? netamt?.toFixed(2) : subtotal?.toFixed(2);

			setSetServiceAmount(serviceAmount);
			setDiscountAmount(discountAmt);
			setSetTotalAmount(totalAmt);

			for (let i = 0; i < 7; i++) {
				setCopied((pre) => [...pre, { timer: null, iscoppied: false }]);
			}
		}
	}, [bookings, discount]);

	const onCheckCopy = (index) => {
		if (copied[index]) {
			if (copied[index].iscoppied == false) {
				setCopied((pre) => {
					let tempVal = pre[index];
					tempVal.iscoppied = true;
					return [...pre, tempVal];
				});
			}
			if (copied[index].timer) {
				clearTimeout(copied[index].timer);
			}

			copied[index].timer = setTimeout(() => {
				setCopied((pre) => {
					let tempVal = pre[index];
					tempVal.iscoppied = false;
					return [...pre, tempVal];
				});
			}, 2000);
		}
	};

	const handleChange = (id) => {
		setSelectedValue(id.toString());
	};

	const onValueChange = (e) => {
		console.log('e.target.value', e.target.value);
		setAdvanceAmount(e.target.value);
	};

	const onPressPayBtn = async () => {
		let cansendapi;
		setAmountError({ error: false, msg: '*Required' });

		if (isHalfPayment) {
			if (!advanceAmount) {
				setAmountError({ error: true, msg: '*Required' });
				cansendapi = false;
			} else if (Number(advanceAmount) === 0) {
				setAmountError({ error: true, msg: 'Amount cannot be 0' });
				cansendapi = false;
			} else if (Number(advanceAmount) > 0 && Number(advanceAmount) < 1) {
				setAmountError({ error: true, msg: 'Amount must be at least 1' });
				cansendapi = false;
			} else {
				cansendapi = handleCheckInputKeyUpReg(advanceAmount, setAmountError, priceRegex);
			}
		} else {
			setAmountError({ error: false, msg: '*Required' });
			cansendapi = true;
		}

		if (cansendapi) {
			const formData = {};
			formData['bookings'] = bookings.join(',');
			formData['action'] = 'add_order';
			formData['promocode'] = discount ? (discount > 0 ? promocode : '') : '';

			const flechdata = await apiService(formData, token, setToken, navigate);
			const redirectData = {
				message: flechdata.message,
				type: flechdata.type,
			};

			navigate('/cart', { state: redirectData, replace: true });
		}
	};

	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 1250);
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<PageWrapper>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card>
							<CardHeader borderSize={1}>
								<CardActions className='w-100'>
									<div className='row'>
										<CardTitle className='col-auto payment-title'>
											Checkout :
										</CardTitle>
										<div className='col-auto payment-header-image'>
											<img
												src='https://cdn.schedulerdesk.com/uploads/settings/6188_SD_logo.png'
												width={200}
											/>
										</div>
									</div>
								</CardActions>
							</CardHeader>
							<CardBody className='row d-flex pb-0'>
								<Card className='payment-list'>
									<CardHeader className={`ps-0 ${isMobile ? 'pt-0' : ''}`}>
										<CardActions className='w-100'>
											<div>
												<h4>Pay with</h4>
											</div>
										</CardActions>
									</CardHeader>

									<div className='payment-method-body'>
										{paymentMethodList.map((item) => (
											<div
												key={item.id}
												className='payment-method-item'
												onClick={(e) => handleChange(item.id)}>
												<Radio
													className='payment-method-radio'
													checked={selectedValue == item.id}
													//onChange={handleChange}
													value={item.id}
													sx={{
														color: '#495057',
														'&.Mui-checked': {
															color: 'white',
														},
													}}
													inputProps={{ 'aria-label': item.name }}
												/>
												<img
													className='payment-method-icon'
													src={item.icon}
													height={40}
												/>
												<span className='payment-method-name'>
													{item.name}
												</span>
											</div>
										))}
									</div>
									{selectedValue == '4' && (
										<div
											className={`bank-transfer-details ${
												selectedValue == '4' ? 'active' : ''
											}`}>
											<h5>Bank Transfer Details</h5>

											<div className='row'>
												<p
													className={`col-${isMobile ? 5 : 2} pe-0`}
													style={{
														fontWeight: 400,
													}}>
													Bank Name
												</p>
												<p className={`col-${isMobile ? 7 : 10} px-0`}>
													: &nbsp; Sampath Bank
												</p>
											</div>
											<div className='row'>
												<p
													className={`col-${isMobile ? 5 : 2} pe-0`}
													style={{
														fontWeight: 400,
													}}>
													Account Name
												</p>
												<p className={`col-${isMobile ? 7 : 10} px-0`}>
													: &nbsp; Scheduler Desk
												</p>
											</div>
											<div className='row'>
												<p
													className={`col-${isMobile ? 5 : 2} pe-0`}
													style={{
														fontWeight: 400,
													}}>
													Account Number
												</p>
												<div
													className={`col-${isMobile ? 7 : 10} px-0`}
													style={{
														display: 'flex',
														alignItems: 'center',
													}}>
													<p style={{ marginRight: '10px' }}>
														: &nbsp; 8236100046698
													</p>
													<Popover
														isOpen={isPopoverOpen[0]}
														positions={['left']}
														content={
															<div>
																<span
																	style={{
																		backgroundColor:
																			'rgba(0, 0, 0, 0.85)',
																		borderRadius: '5px',
																		bottom: '1px',
																		boxShadow:
																			'0 0 5px rgba(0, 0, 0, 0.4)',
																		color: '#fff',
																		fontSize: '12px',
																		fontFamily:
																			'Helvetica, sans-serif',
																		left: '-45px',
																		padding: '7px 10px',
																		position: 'absolute',
																		width: '48px',
																		zIndex: 4,
																	}}>
																	Copy
																</span>
															</div>
														}>
														<div
															onMouseEnter={() =>
																setIsPopoverOpen((pre) => {
																	const temp = [...pre];
																	temp[0] = true;
																	return temp;
																})
															}
															onMouseLeave={() =>
																setIsPopoverOpen((pre) => {
																	const temp = [...pre];
																	temp[0] = false;
																	return temp;
																})
															}>
															<CopyToClipboard
																text={'8236100046698'}
																onCopy={() => onCheckCopy(0)}>
																<span style={{ cursor: 'pointer' }}>
																	<Icon
																		icon={'ContentCopy'}
																		iconColor='info'
																	/>
																</span>
															</CopyToClipboard>
														</div>
													</Popover>
													<span
														style={{
															fontSize: '12px',
															marginLeft: '10px',
															color: 'green',
															fontWeight: 'bold',
														}}>
														{copied[0] &&
															copied[0].iscoppied &&
															'copied'}
													</span>
												</div>
											</div>
											<div className='row'>
												<p
													className={`col-${isMobile ? 5 : 2} pe-0`}
													style={{
														fontWeight: 400,
													}}>
													Branch
												</p>
												<p className={`col-${isMobile ? 7 : 10} px-0`}>
													: &nbsp; Colombo
												</p>
											</div>
											<div className='row'>
												<p
													className={`col-${isMobile ? 5 : 2} pe-0`}
													style={{
														fontWeight: 400,
													}}>
													Branch Code
												</p>
												<div
													className={`col-${isMobile ? 7 : 10} px-0`}
													style={{
														display: 'flex',
														alignItems: 'center',
													}}>
													<p style={{ marginRight: '10px' }}>
														: &nbsp; 034
													</p>
													<Popover
														isOpen={isPopoverOpen[1]}
														positions={['left']}
														content={
															<div>
																<span
																	style={{
																		backgroundColor:
																			'rgba(0, 0, 0, 0.85)',
																		borderRadius: '5px',
																		bottom: '1px',
																		boxShadow:
																			'0 0 5px rgba(0, 0, 0, 0.4)',
																		color: '#fff',
																		fontSize: '12px',
																		fontFamily:
																			'Helvetica, sans-serif',
																		left: '-45px',
																		padding: '7px 10px',
																		position: 'absolute',
																		width: '48px',
																		zIndex: 4,
																	}}>
																	Copy
																</span>
															</div>
														}>
														<div
															onMouseEnter={() =>
																setIsPopoverOpen((pre) => {
																	const temp = [...pre];
																	temp[1] = true;
																	return temp;
																})
															}
															onMouseLeave={() =>
																setIsPopoverOpen((pre) => {
																	const temp = [...pre];
																	temp[1] = false;
																	return temp;
																})
															}>
															<CopyToClipboard
																text={'034'}
																onCopy={() => onCheckCopy(1)}>
																<span style={{ cursor: 'pointer' }}>
																	<Icon
																		icon={'ContentCopy'}
																		iconColor='info'
																	/>
																</span>
															</CopyToClipboard>
														</div>
													</Popover>
													<span
														style={{
															fontSize: '12px',
															marginLeft: '10px',
															color: 'green',
															fontWeight: 'bold',
														}}>
														{copied[1] &&
															copied[1].iscoppied &&
															'copied'}
													</span>
												</div>
											</div>
											<div className='row'>
												<p
													className={`col-${isMobile ? 5 : 2} pe-0`}
													style={{
														fontWeight: 400,
													}}>
													Bank Code
												</p>
												<div
													className={`col-${isMobile ? 7 : 10} px-0`}
													style={{
														display: 'flex',
														alignItems: 'center',
													}}>
													<p style={{ marginRight: '10px' }}>
														: &nbsp; 7218
													</p>
													<Popover
														isOpen={isPopoverOpen[2]}
														positions={['left']}
														content={
															<div>
																<span
																	style={{
																		backgroundColor:
																			'rgba(0, 0, 0, 0.85)',
																		borderRadius: '5px',
																		bottom: '1px',
																		boxShadow:
																			'0 0 5px rgba(0, 0, 0, 0.4)',
																		color: '#fff',
																		fontSize: '12px',
																		fontFamily:
																			'Helvetica, sans-serif',
																		left: '-45px',
																		padding: '7px 10px',
																		position: 'absolute',
																		width: '48px',
																		zIndex: 4,
																	}}>
																	Copy
																</span>
															</div>
														}>
														<div
															onMouseEnter={() =>
																setIsPopoverOpen((pre) => {
																	const temp = [...pre];
																	temp[2] = true;
																	return temp;
																})
															}
															onMouseLeave={() =>
																setIsPopoverOpen((pre) => {
																	const temp = [...pre];
																	temp[2] = false;
																	return temp;
																})
															}>
															<CopyToClipboard
																text={'7218'}
																onCopy={() => onCheckCopy(2)}>
																<span style={{ cursor: 'pointer' }}>
																	<Icon
																		icon={'ContentCopy'}
																		iconColor='info'
																	/>
																</span>
															</CopyToClipboard>
														</div>
													</Popover>
													<span
														style={{
															fontSize: '12px',
															marginLeft: '10px',
															color: 'green',
															fontWeight: 'bold',
														}}>
														{copied[2] &&
															copied[2].iscoppied &&
															'copied'}
													</span>
												</div>
											</div>
											<div className='row'>
												<p
													className={`col-${isMobile ? 5 : 2} pe-0`}
													style={{
														fontWeight: 400,
													}}>
													SWIFT Code
												</p>
												<div
													className={`col-${isMobile ? 7 : 10} px-0`}
													style={{
														display: 'flex',
														alignItems: 'center',
													}}>
													<p style={{ marginRight: '10px' }}>
														: &nbsp; HRERR382H3
													</p>
													<Popover
														isOpen={isPopoverOpen[3]}
														positions={['left']}
														content={
															<div>
																<span
																	style={{
																		backgroundColor:
																			'rgba(0, 0, 0, 0.85)',
																		borderRadius: '5px',
																		bottom: '1px',
																		boxShadow:
																			'0 0 5px rgba(0, 0, 0, 0.4)',
																		color: '#fff',
																		fontSize: '12px',
																		fontFamily:
																			'Helvetica, sans-serif',
																		left: '-45px',
																		padding: '7px 10px',
																		position: 'absolute',
																		width: '48px',
																		zIndex: 4,
																	}}>
																	Copy
																</span>
															</div>
														}>
														<div
															onMouseEnter={() =>
																setIsPopoverOpen((pre) => {
																	const temp = [...pre];
																	temp[3] = true;
																	return temp;
																})
															}
															onMouseLeave={() =>
																setIsPopoverOpen((pre) => {
																	const temp = [...pre];
																	temp[3] = false;
																	return temp;
																})
															}>
															<CopyToClipboard
																text={'HRERR382H3'}
																onCopy={() => onCheckCopy(3)}>
																<span style={{ cursor: 'pointer' }}>
																	<Icon
																		icon={'ContentCopy'}
																		iconColor='info'
																	/>
																</span>
															</CopyToClipboard>
														</div>
													</Popover>
													<span
														style={{
															fontSize: '12px',
															marginLeft: '10px',
															color: 'green',
															fontWeight: 'bold',
														}}>
														{copied[3] &&
															copied[3].iscoppied &&
															'copied'}
													</span>
												</div>
											</div>
										</div>
									)}
								</Card>

								{!isMobile && (
									<div style={{ width: '2%' }}>
										<DividerANT
											style={{
												height: '100%',
												borderInlineStart: '1px solid #373b44',
											}}
											type='vertical'
											variant='solid'
										/>
									</div>
								)}

								<CardBody
									className={`payment-right-panel px-0 pt-0 ${
										isMobile ? 'pb-0' : ''
									}`}>
									<Card className='p-3 card-background'>
										<div>
											<div className='payment-summary-item'>
												<span>
													Services Amount [{bookings.length} items]
												</span>
												<span>{ServiceAmount} LKR</span>
											</div>
											{discount && (
												<div className='payment-summary-item'>
													<span>Discount</span>
													<span>({discountAmount}) LKR</span>
												</div>
											)}
											<Divider sx={{ margin: '10px' }} />

											<div className='payment-summary-item'>
												<h4>Order total</h4>
												<span
													style={{
														fontSize: '1.2rem',
														fontWeight: '700',
													}}>
													{totalAmount} LKR
												</span>
											</div>
											<div
												className='payment-method-item mt-3'
												style={{
													display: 'flex',
													alignItems: 'center',
												}}>
												<div
													onClick={(e) => {
														setHalfPayment((pre) => !pre);
													}}>
													<Checkbox
														className='payment-method-radio'
														checked={isHalfPayment === true}
														onChange={(e) => {
															setHalfPayment(e.target.checked);
														}}
														value={false}
														sx={{
															color: '#495057',
															'&.Mui-checked': {
																color: 'white',
															},
														}}
														inputProps={{
															'aria-label': 'half payment',
														}}
													/>
													<span className='m-auto'>Split payment</span>
												</div>
												<HelpIconPopOver
													text={
														"Split payment meaning: what is a split payment? When a customer uses more than one payment method to settle a single transaction, this is called a split payment. For example, a customer might put the total cost of their transaction on two different credit cards. Split payments aren't always used by single customers."
													}
												/>
											</div>
											{isHalfPayment && (
												<div className='py-3'>
													<FormGroup
														id='advance'
														label='Split payment Amount'
														className='m-auto'>
														<Input
															className='payment-split-input'
															style={{
																border: isAmountError.error
																	? '2px solid red'
																	: '2px solid transparent',
															}}
															type='text'
															placeholder='Enter 150 or more'
															onChange={onValueChange}
															value={advanceAmount}
														/>
													</FormGroup>
													{isAmountError.error && (
														<p
															style={{
																color: 'red',
																fontSize: '12px',
															}}>
															{isAmountError.msg}
														</p>
													)}
												</div>
											)}
											<div className='row mt-3'>
												<Button
													className='payment-summary-button'
													color={'primary'}
													disabled={!selectedValue}
													onClick={() => {
														if (selectedValue === '4') {
															onPressPayBtn();
														}
													}}>
													Pay with
													{paymentMethodList[
														paymentMethodList.findIndex(
															(value) =>
																value.id.toString() ==
																selectedValue,
														)
													] &&
														paymentMethodList[
															paymentMethodList.findIndex(
																(value) =>
																	value.id.toString() ==
																	selectedValue,
															)
														].name}
												</Button>
											</div>
										</div>
									</Card>
								</CardBody>
							</CardBody>
							<CardBody className={`row d-flex ${isMobile ? 'pt-0' : ''}`}>
								<Card className='card-background'>
									<CardHeader className=' ps-0'>
										<CardActions className='w-100'>
											<div>
												<h4>Order Summary</h4>
											</div>
										</CardActions>
									</CardHeader>
									<div className='table-responsive'>
										<table className='table table-modern table-striped'>
											<thead>
												<tr className='order-summary-tr'>
													<th scope='col'>ID</th>
													<th scope='col'>Date Time</th>
													<th scope='col'>Service</th>
													<th scope='col' className='text-right'>
														Duration
													</th>
													<th scope='col'>Price (LKR)</th>
													<th scope='col'>Discount (LKR)</th>
												</tr>
											</thead>
											<tbody>
												{promo.map((item) => (
													<tr key={item.id}>
														<td>{item.id}</td>
														<td
															style={{
																minWidth: isMobile && '140px',
															}}>
															{item.bdate_formated} |{' '}
															{item.bfrom_formated}
														</td>
														<td>{item.service_name}</td>
														<td>{`${item.service_duration} Min`}</td>
														<td>{item.service_price}</td>
														<td>{(item.discount ?? 0).toFixed(2)}</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</Card>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default CheckoutPage;
