export const numberRegex = /^[0-9]+$/;
export const phonecodeRegex = /^[0-9+]+$/;
export const nicRegex = /^[0-9Vv]+$/;
// export const priceRegex = /^[0-9.]+$/;
// export const priceRegex = /^-?\d*\.?\d+$/;
export const priceRegex = /^\d+(\.\d{1,2})?$/;
export const zipRegex = /^[-_a-zA-Z0-9.]+$/;
export const charRegex = /^[a-zA-Z\s]+$/;
// export const charRegex = /^[a-zA-Z]+$/;

export const handleInputKeyUpReg = (
	event,
	setterFunction = () => {},
	validationregex,
	method = 1,
	key,
) => {
	const input = String(event.target.value).split('');
	const validinput = [];

	const validation = validationregex.test(input);
	input.forEach((data) => {
		if (validationregex.test(data)) {
			validinput.push(data);
		}
	});
	if (method === 1) {
		setterFunction(validinput.join(''));
	}
	if (method === 2) {
		setterFunction((tre) => {
			const temp = {
				...tre,
			};
			temp[key] = validinput.join('');
			return temp;
		});
	}
};

export const handleCheckInputKeyUpReg = (inputdata, setterFunction = () => {}, validationregex) => {
	const validation = validationregex.test(inputdata);
	//console.log("input val",validation)
	if (!validation) {
		setterFunction({
			error: true,
			msg: 'Only integers or decimals numbers with up to 2 decimal places accepted',
		});
		return false;
	} else {
		return true;
	}
};

export const handleCheckInputPhoneCode = (
	inputdata,
	setterFunction = () => {},
	validationregex,
) => {
	const validation = validationregex.test(inputdata);
	//console.log("input val",validation)
	if (!validation) {
		setterFunction({
			error: true,
			msg: 'Only integers and + Symbol accepted',
		});
		return false;
	} else {
		return true;
	}
};

export const handleCheckInputNumbers = (inputdata, setterFunction = () => {}, validationregex) => {
	const validation = validationregex.test(inputdata);
	//console.log("input val",validation)
	if (!validation) {
		setterFunction({
			error: true,
			msg: 'Only integers accepted',
		});
		return false;
	} else {
		return true;
	}
};

export const handleCheckInputNic = (inputdata, setterFunction = () => {}, validationregex) => {
	const validation = validationregex.test(inputdata);
	//console.log("input val",validation)
	if (!validation) {
		setterFunction({
			error: true,
			msg: 'Only integers and V character accepted',
		});
		return false;
	} else {
		return true;
	}
};

export const checkInput = (
	inputdata,
	setterFunction = () => {},
	validationregex,
	inputkey,
	message,
) => {
	const validation = validationregex.test(inputdata[inputkey]);
	//console.log("input val",validation)
	if (!validation) {
		setterFunction((pre) => {
			const temp = { ...pre };
			temp[inputkey] = {
				error: true,
				msg: message,
			};
			return temp;
		});
		return false;
	} else {
		return true;
	}
};

export const multiinputcheck = (inputArray = [], input = {}, errorsetter, validatedobj = {}) => {
	const cansendapi = [];
	inputArray.forEach((inputkey) => {
		//console.log("multi error",input[inputkey])
		if (!input[inputkey]) {
			cansendapi.push(false);
			//console.log("ABC")
			errorsetter((pre) => {
				const temp = { ...pre };
				temp[inputkey] = { error: true, msg: '*Required' };
				return temp;
			});
		} else {
			if (validatedobj[inputkey]) {
				const out = checkInput(
					input,
					errorsetter,
					validatedobj[inputkey][0],
					inputkey,
					validatedobj[inputkey][1],
				);
				cansendapi.push(out);
			} else {
				cansendapi.push(true);
			}
		}
	});
	//console.log("cansendapi",cansendapi)
	if (cansendapi.includes(false)) {
		return false;
	} else {
		return true;
	}
};
