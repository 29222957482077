import React from 'react';
import 'rodal/lib/rodal.css';
import Countdown from 'react-countdown';
import Fade from '@mui/material/Fade';
import Icon from '../../../components/icon/Icon';
import PropTypes from 'prop-types';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';

const renderer = ({ hours, minutes, seconds, completed }) => {
	if (completed) {
		// Render a completed state
		// startmodelcartitem();
		// return <Completionist />;
		return null;
	} else {
		// Render a countdown
		return (
			// <div
			// 	style={{
			// 		border: '3px solid red',
			// 		borderRadius: '10px',
			// 		padding: '10px',
			// 		display: 'inline-block',
			// 	}}>
			// 	<span style={{ color: 'red', fontSize: '15px', fontWeight: 'bold' }}>
			// 		{minutes}:{seconds}
			// 		{/* {hours}:{minutes}:{seconds} */}
			// 	</span>
			// </div>

			// <>
			// 	{minutes}:{seconds}
			// </>

			<div style={{ display: 'inline', fontWeight: 'bold' }}>
				{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
			</div>
		);
	}
};

export const Ralert = ({
	msgloadalert,
	msgtypeloadalert,
	msgsmtext,
	loadalert,
	closemodel,
	confirmbt,
}) => {
	// const { darkModeStatus, themeStatus } = useDarkMode();

	//const capitalizedText = text => text.charAt(0).toUpperCase() + text.slice(1);

	//console.log("Loadalert",msgtypeloadalert)
	let alerttype = 'Done';
	if (msgtypeloadalert === 'error' || msgtypeloadalert === 'Error') {
		alerttype = 'ErrorOutline';
	} else if (msgtypeloadalert === 'Success' || msgtypeloadalert === 'success') {
		alerttype = 'CheckCircleOutline';
	}

	let altcolor = 'primary';
	if (msgtypeloadalert === 'Error' || msgtypeloadalert === 'error') {
		altcolor = 'danger';
	} else if (msgtypeloadalert === 'Success' || msgtypeloadalert === 'success') {
		altcolor = 'primary';
		//altcolor = 'danger';
	} else {
		altcolor = 'warning';
	}

	return (
		<Modal
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			open={loadalert}
			onClose={(_, reason) => reason === 'backdropClick' && closemodel}
			closeAfterTransition
			slots={{ backdrop: StyledBackdrop }}>
			<Fade in={loadalert}>
				<ModalContent
					sx={
						{
							/*
						border:
							altcolor === 'danger'
								? ' 2px solid rgb(253, 83, 97)'
								: altcolor === 'primary'
								? ' 2px solid #3aba6f'
								: '0px solid transparent' //' 2px solid #ffc107',
					*/
						}
					}>
					<div
						style={{
							height: '300px',
							width: '450px',
							flexDirection: 'column',
							position: 'relative',
						}}>
						<div
							style={{
								height: '80%',
								//backgroundColor:altcolor === 'danger' ? 'rgb(253, 83, 97)' : '#3aba6f',
								alignContent: 'center',
							}}>
							{/* <div style={{ position: 'absolute', top: '20px', right: '20px' }}>
								<Countdown
									date={Date.now() + 60000 * 2}
									renderer={renderer}
									key={0}
								/>
							</div> */}
							<center>
								<div
									className='mt-2'
									style={{
										height: '70px',
										width: '70px',
										//background: 'white',
										alignContent: 'center',
										justifyItems: 'center',
										borderRadius: '100px',
										border:
											altcolor === 'danger'
												? ' 4px solid rgb(253, 83, 97)'
												: altcolor === 'primary'
												? ' 4px solid #3aba6f'
												: ' 4px solid #ffc107',
									}}>
									<Icon
										icon={
											altcolor === 'danger'
												? 'X'
												: altcolor === 'primary'
												? 'Check'
												: 'Exclamation'
										}
										color={
											altcolor === 'danger'
												? 'danger'
												: altcolor === 'primary'
												? 'success'
												: 'warning'
										}
										size='5x'
										forceFamily='bootstrap'
									/>
								</div>
							</center>

							<div
								className='mt-4'
								style={{
									// color: 'black',
									//color: themeStatus === 'dark' ? '#e5e5e5' : '#141414',
									fontSize: '22px',
									fontWeight: 500,
									display: 'flex',
									width: '100%',
									alignItems: 'center',
									flexDirection: 'column',
									justifyContent: 'space-between',
									fontWeight: 'bold',
									textShadow: '0px 3px 12px 0px rgb(0 0 0 / 0.4)',
								}}>
								{msgtypeloadalert.toLocaleUpperCase()}
							</div>
							<div
								className='mt-1'
								style={{
									// color: 'black',
									//color: themeStatus === 'dark' ? '#e5e5e5' : '#141414',
									fontSize: '15px',
									display:
										msgtypeloadalert === 'Session Timeout Warning'
											? 'inline'
											: 'flex',
									width: '100%',
									alignItems: 'center',
									flexDirection: 'column',
									justifyContent: 'space-between',
									//fontWeight: 'bold',
									textShadow: '0px 3px 12px 0px rgb(0 0 0 / 0.4)',
									paddingInline: '6px',
								}}>
								{msgtypeloadalert === 'Session Timeout Warning' ? (
									<>
										Your booking session will expire in{' '}
										<Countdown
											date={Date.now() + 60000 * 2}
											renderer={renderer}
											key={0}
										/>{' '}
										due to inactivity. Do you need more time to complete your
										booking?
									</>
								) : (
									msgloadalert
								)}
							</div>
							{msgsmtext && (
								<div
									className='mt-1'
									style={{
										// color: 'black',
										//color: themeStatus === 'dark' ? '#e5e5e5' : '#141414',
										fontSize: '11px',
										display: 'flex',
										width: '100%',
										alignItems: 'center',
										flexDirection: 'column',
										justifyContent: 'space-between',
										//fontWeight: 'bold',
										textShadow: '0px 3px 12px 0px rgb(0 0 0 / 0.4)',
									}}>
									{msgsmtext}
								</div>
							)}
						</div>
						<div
							className='row'
							style={{
								height: '20%',
								alignContent: 'center',
								backgroundColor:
									altcolor === 'danger'
										? 'rgb(253, 83, 97)'
										: altcolor === 'primary'
										? '#3aba6f'
										: '#ffc107',
							}}>
							<div
								className='col text-center h-100 d-flex align-items-center justify-content-center'
								style={{ backgroundColor: altcolor === 'warning' && '#e6e6e6' }}>
								<button
									type='button'
									class='btn btn'
									// onClick={()=>{
									// 	console.log("closed....")
									// }}
									onClick={closemodel}
									data-mdb-ripple-init
									style={{
										height: '100%',
										width: '100%',
										fontSize: '18px',
										boxShadow: 'none',
										//backgroundColor: '#e6e6e6',
										color: altcolor !== 'warning' && 'white',
									}}>
									NO
								</button>
							</div>

							{altcolor === 'warning' && (
								<div className='col d-flex align-items-center justify-content-center'>
									<button
										type='button'
										class='btn btn'
										onClick={confirmbt}
										data-mdb-ripple-init
										style={{
											height: '100%',
											width: '100%',
											fontSize: '18px',
											boxShadow: 'none',
											//backgroundColor: '#e6e6e6',
											color: 'white',
										}}>
										YES
									</button>
								</div>
							)}
						</div>
					</div>
				</ModalContent>
			</Fade>
		</Modal>
	);
};

const Backdrop = React.forwardRef((props, ref) => {
	const { open, ...other } = props;
	return (
		<Fade in={open}>
			<div ref={ref} {...other} />
		</Fade>
	);
});

Backdrop.propTypes = {
	open: PropTypes.bool,
};

const blue = {
	200: '#99CCFF',
	300: '#66B2FF',
	400: '#3399FF',
	500: '#007FFF',
	600: '#0072E5',
	700: '#0066CC',
};

const grey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025',
};

const Modal = styled(BaseModal)`
	position: fixed;
	z-index: 1300;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
	z-index: -1;
	position: fixed;
	inset: 0;
	background-color: rgb(0 0 0 / 0.57);
	-webkit-tap-highlight-color: transparent;
`;
//rgb(181 171 171 / 0.3)
const ModalContent = styled('div')(
	({ theme }) => css`
		text-align: center;
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 8px;
		overflow: hidden;
		background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
		border-radius: 20px;
		border: 0px solid transparent;
		outline: none;
		box-shadow: 0 4px 12px
			${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
		padding: 0px;
		color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

		& .modal-title {
			color: white;
			margin: 0;
			line-height: 1.5rem;
			margin-bottom: 8px;
		}

		& .modal-description {
			margin: 0;
			line-height: 1.5rem;
			font-weight: 400;
			color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
			margin-bottom: 4px;
		}
	`,
);
