import React from 'react';
import Card, {
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import Badge from '../../components/bootstrap/Badge';
import { priceFormat } from '../../helpers/helpers';
import { layoutMenu } from '../../menu';
import '../common/Card.css';
import useDarkMode from '../../hooks/useDarkMode';
import PlaceholderImage from '../../components/extras/PlaceholderImage';

const CommonGridProductItemSwl = ({
	// eslint-disable-next-line react/prop-types
	id,
	// eslint-disable-next-line react/prop-types
	name,
	// eslint-disable-next-line react/prop-types
	category,
	// eslint-disable-next-line react/prop-types
	img,

	// eslint-disable-next-line react/prop-types
	price,
}) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<Card className='card-cus' style={{ width: '300px', height: '300px' }}>
			<CardHeader>
				<CardLabel>
					<CardTitle>
						{name}{' '}
						{/* {price && (
							<Badge color='success' isLight className='ms-2'>
								{priceFormat(price)}
							</Badge>
						)} */}
					</CardTitle>
					<CardSubTitle>{category}</CardSubTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				{/* <img style={{width:'150px', height:'150px', borderRadius:'12px'}}
					src={img}
					alt=''
					width={100}
					height={100}
					className='mx-auto d-block img-fluid mb-3'
				/> */}
				{img ? (
					<img
						style={{
							objectFit: 'cover',
							width: '250px',
							height: '150px',
							borderRadius: '12px',
						}}
						src={img}
						alt=''
						width={100}
						height={100}
						className='mx-auto d-block img-fluid mb-3'
					/>
				) : (
					<PlaceholderImage
						width={150}
						height={150}
						className='mx-auto d-block img-fluid mb-3 rounded'
					/>
				)}
				{price && (
					<Badge color='success' isLight className='ms-2' width='150px' height='150px'>
						{priceFormat(price)}
					</Badge>
				)}
			</CardBody>
			<CardFooter className='shadow-3d-container' style={{ paddingTop: 0 }}>
				<Button
					color='dark'
					className={`w-100 mb-4-${darkModeStatus ? 'light' : 'dark'}`}
					// className={`w-100 mb-4 shadow-3d-up-hover shadow-3d-${
					// 	darkModeStatus ? 'light' : 'dark'
					// }`}
					size='lg'
					tag='a'
					to={`../${layoutMenu.cbookingPages.subMenu.view_servwl.path}/${id}`}>
					View Service
				</Button>
			</CardFooter>
		</Card>
	);
};

export default CommonGridProductItemSwl;
